solumaxFp
    .factory('LinkFactory', function() {

        var x = window.location.hostname.substring(0, 3)
        var env = (x == '192' || x == 'loc') ? 'dev' : 'prod';

        var domains = {
            accounts: 'https://accounts.xolura.com/',
        }

        var apps = {
            authentication: domains.accounts + 'views/user/',
        }

        var urls = {

            authentication: {
                login: apps.authentication + 'authentication/login',
                tenantSelect: apps.authentication + 'token-exchange/tenant-select'
            },

        }

        return urls
    })

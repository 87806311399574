solumaxFp
    .controller('SalesUploadController', function(
        CsvUploader) {

        var vm = this

        vm.fakturs = []

        function fixdata(data) {
            var o = "",
                l = 0,
                w = 10240;
            for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
            o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
            return o;
        }

        vm.loadFile = function() {

            console.log('reached')

            var file = document.getElementById('file').files[0];

            var reader = new FileReader();
            reader.onload = function(e) {
                var data = e.target.result;

                var arr = fixdata(data);
                wb = XLSX.read(btoa(arr), { type: 'base64' })

                processFileContent(wb.Sheets)
            }

            reader.readAsArrayBuffer(file)
        }


        function processFileContent(sheets) {

            vm.fakturs = XLSX.utils.sheet_to_json(sheets['FAKTUR'])

            var details = XLSX.utils.sheet_to_json(sheets['DATA'])

            for (let faktur of vm.fakturs) {

                faktur.details = _.filter(details, { 'NOMOR_FAKTUR': faktur['NOMOR_FAKTUR'] })
            }
        }

        var csvHeader = {
            1: ['FK',
                'KD_JENIS_TRANSAKSI',
                'FG_PENGGANTI',
                'NOMOR_FAKTUR',
                'MASA_PAJAK',
                'TAHUN_PAJAK',
                'TANGGAL_FAKTUR',
                'NPWP',
                'NAMA',
                'ALAMAT_LENGKAP',
                'JUMLAH_DPP',
                'JUMLAH_PPN',
                'JUMLAH_PPNBM',
                'ID_KETERANGAN_TAMBAHAN',
                'FG_UANG_MUKA',
                'UANG_MUKA_DPP',
                'UANG_MUKA_PPN',
                'UANG_MUKA_PPNBM',
                'REFERENSI',
                'KODE_DOKUMEN_PENDUKUNG'
            ],
            2: ['LT',
                'NPWP',
                'NAMA',
                'JALAN',
                'BLOK',
                'NOMOR',
                'RT',
                'RW',
                'KECAMATAN',
                'KELURAHAN',
                'KABUPATEN',
                'PROPINSI',
                'KODE_POS',
                'NOMOR_TELEPON',
            ],
            3: [
                'OF',
                'KODE_OBJEK',
                'NAMA',
                'HARGA_SATUAN',
                'JUMLAH_BARANG',
                'HARGA_TOTAL',
                'DISKON',
                'DPP',
                'PPN',
                'TARIF_PPNBM',
                'PPNBM',
            ]
        }



        vm.downloadFile = function() {
            var data = []

            _.each(csvHeader, function(val) {
                data.push(val)
            })

            _.each(vm.fakturs, function(faktur, key) {

                data.push([
                    'FK',
                    '01',
                    0,
                    _.get(faktur, 'NOMOR_FAKTUR').replace(/(\r\n|\n|\r|\s|\.|\-)/gm, ''),
                    _.get(faktur, 'MASA_PAJAK'),
                    _.get(faktur, 'TAHUN_PAJAK'),
                    _.get(faktur, 'TANGGAL_FAKTUR'),
                    _.get(faktur, 'NPWP').replace(/(\r\n|\n|\r|\s|\.|\-)/gm, ''),
                    _.get(faktur, 'NAMA').replace(/(\r\n|\n|\r)/gm, ''),
                    _.get(faktur, 'ALAMAT_LENGKAP').replace(/(\r\n|\n|\r)/gm, ''),
                    _.round(_.get(faktur, 'JUMLAH_DPP')),
                    _.round(_.get(faktur, 'JUMLAH_PPN')),
                    0,
                    '',
                    0,
                    0,
                    0,
                    0,
                    _.get(faktur, 'REFERENSI'),
                    ''
                ])


                if (_.isEmpty(_.get(faktur, 'NPWP'))) {

                    data.push([
                        'LT',
                        '100000000000000',
                        _.get(faktur, 'NAMA'),
                        _.get(faktur, 'ALAMAT_LENGKAP'),
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ])

                } else {

                    data.push([
                        'LT',
                        _.get(faktur, 'NPWP').replace(/(\r\n|\n|\r|\s|\.|\-)/gm, ''),
                        _.get(faktur, 'NAMA'),
                        _.get(faktur, 'ALAMAT_LENGKAP'),
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ])
                }

                _.each(faktur.details, function(detail) {

                    data.push([
                        'OF',
                        _.get(detail, 'KODE_OBJEK'),
                        _.get(detail, 'NAMA'),
                        _.round(_.get(detail, 'HARGA_SATUAN')),
                        _.round(_.get(detail, 'JUMLAH_BARANG')),
                        _.round(_.get(detail, 'HARGA_TOTAL')),
                        _.round(_.get(detail, 'DISKON')),
                        _.round(_.get(detail, 'DPP')),
                        _.round(_.get(detail, 'PPN')),
                        0,
                        0,
                    ])
                })
            })

            var csvContent = "data:text/csv;charset=utf-8,";
            data.forEach(function(infoArray, index) {

                dataString = infoArray.join(",");
                csvContent += index < data.length ? dataString + "\n" : dataString;

            });
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "faktur_upload.csv");
            document.body.appendChild(link); // Required for FF

            link.click();
        }

    })
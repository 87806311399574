solumaxFp
    .config(function($stateProvider, $urlRouterProvider) {

        $urlRouterProvider.otherwise('/index');

        $stateProvider
            .state('index', {
                url: '/index',
                templateUrl: 'app/index/index.html',
                controller: 'IndexController as ctrl',
                requireLogin: false,
                pageTitle: 'Dealer | Base'
            })

            .state('salesUpload', {
                url: '/sales/upload',
                templateUrl: 'app/sales/upload/salesUpload.html',
                controller: 'SalesUploadController as ctrl',
                pageTitle: 'Dealer | Sales Upload'
            })

    })
